
import { Component, Vue } from 'vue-property-decorator';
import StationsMap from '@/components/StationsMap.vue';
import { Store } from 'vuex';
import { IStation } from '@/interfaces';
import { readStations } from '@/store/stations/getters';
import { dispatchGetStations } from '@/store/stations/actions';

@Component({
  components: {
    StationsMap,
  },
  filters: {
    lat: (degrees: number) => {
      const orientation = degrees > 0 ? 'N' : 'S';
      const D = parseInt(String(Math.abs(degrees)), 10);
      const d = Math.abs(degrees) - D;
      const minutes = d * 60;
      const M = parseInt(String(minutes), 10);
      const m = minutes - M;
      const seconds = m * 60;
      const S = parseInt(String(seconds * 100), 10) / 100;

      return `${D}° ${M}' ${S}" ${orientation}`;
    },
    lon: (degrees: number) => {
      const orientation = degrees > 0 ? 'E' : 'W';
      const D = parseInt(String(Math.abs(degrees)), 10);
      const d = Math.abs(degrees) - D;
      const minutes = d * 60;
      const M = parseInt(String(minutes), 10);
      const m = minutes - M;
      const seconds = m * 60;
      const S = parseInt(String(seconds * 100), 10) / 100;

      return `${D}° ${M}' ${S}" ${orientation}`;
    },
  },
})
export default class Stations extends Vue {
  public search = '';

  public headers = [
    {
      text: 'Local',
      sortable: true,
      value: 'location',
      align: 'left',
    },
    {
      text: 'Terminal',
      sortable: true,
      value: 'terminal',
      align: 'left',
    },
    {
      text: 'Longitude',
      sortable: false,
      value: 'longitude',
      align: 'left',
    },
    {
      text: 'Latitude',
      sortable: false,
      value: 'latitude',
      align: 'left',
    },
    {
      text: 'Equipamentos',
      sortable: false,
      value: 'equipments',
      align: 'left',
    },
  ];

  get filteredStations() {
    return this.stations.filter((station) => {
      return (
        station.location.toLowerCase().includes(this.search.toLowerCase()) ||
        station.terminal.toLowerCase().includes(this.search.toLowerCase())
      );
    });
  }

  get stations() {
    return readStations(this.$store);
  }

  public async mounted() {
    await dispatchGetStations(this.$store);
  }
}
